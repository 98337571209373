import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/DeliveryWarehouseStock/helpers'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'
import deliveryWarehouseStockRepository from '@/repository/admin/deliveryWarehouseStockRepository'
import i18n from '@/plugins/vue-i18n'
const { saveAs } = require('file-saver')

const state = {
  filters: StorageService.get(storageKeys.DELIVERY_WAREHOUSE_STOCK_FILTERS) || { ...defaultFilters },
  productFilters: StorageService.get(storageKeys.DELIVERY_PRODUCT_STOCK_FILTERS) || { ...defaultFilters },
  warehouseStockList: [],
  productStockList: [],
  tableOptions: TableOptions.defaultTableOptions(),
  productTableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  productFilters: state => state.productFilters,
  tableOptions: state => state.tableOptions,
  productTableOptions: state => state.productTableOptions,
  warehouseStockList: state => state.warehouseStockList,
  productStockList: state => state.productStockList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadWarehouseStockList(context, payload) {
    const { id, ...filters} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.DELIVERY_WAREHOUSE_STOCK_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: {
        ...appliedFilters,
        warehouse: {
          id: {
            value: id,
            operator: 'in'
          }
        }
      },
      cfFilter: appliedCfFilters,
    })

    const { data } = await deliveryWarehouseStockRepository.list(preparedFilters)

    context.commit('setWarehouseStockList', { data, filters })

    return { warehouseStockList: data.items, pagination: data.pagination }
  },
  async loadProductStockList(context, payload) {
    const { id, ...filters} = payload
    context.commit('setProductFilters', filters)
    StorageService.set(storageKeys.DELIVERY_PRODUCT_STOCK_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: {
        ...appliedFilters,
        product: {
          id: {
            value: id,
            operator: 'in'
          }
        }
      },
      cfFilter: appliedCfFilters,
    })

    const { data } = await deliveryWarehouseStockRepository.list(preparedFilters)

    context.commit('setProductStockList', { data, filters })

    return { productStockList: data.items, pagination: data.pagination }
  },
  async searchWarehouseStockList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await deliveryWarehouseStockRepository.list(preparedFilters)

    return { warehouseStockList: data.items, pagination: data.pagination }
  },
  async loadWarehouseStock(context, id) {
    const { data } = await deliveryWarehouseStockRepository.get(id)
    return { warehouseStock: data }
  },
  createWarehouseStock(context, payload) {
    return deliveryWarehouseStockRepository.post(payload)
  },
  updateWarehouseStock(context, payload) {
    const { id, ...data } = payload
    return deliveryWarehouseStockRepository.put(id, data)
  },
  async loadExportFile(context, filters) {
    const response = await deliveryWarehouseStockRepository.export(
      { warehouses: filters },
      { responseType: 'blob' }
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('deliveryWarehouse.remainingGoodsExport')}.xlsx`
    saveAs(blob, filename)
    return response
  },
  deleteWarehouseStock(context, payload) {
    return deliveryWarehouseStockRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.DELIVERY_WAREHOUSE_STOCK_FILTERS)
  },
  filtersProductStorageReset() {
    StorageService.remove(storageKeys.DELIVERY_PRODUCT_STOCK_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.DELIVERY_WAREHOUSE_STOCK_FILTERS, updatedFilters)
  },
}

const mutations = {
  setWarehouseStockList(state, { data, filters }) {
    state.warehouseStockList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setProductStockList(state, { data, filters }) {
    state.productStockList = [...data.items]
    state.productTableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setProductFilters(state, filters) {
    state.productFilters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
