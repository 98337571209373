export default [
  {
    path: '/logistics/export-reports',
    name: 'exportReportsLogistics',
    meta: {
      title: 'page.exportReports',
      roles: [
        'LOGISTIC_REPORT_EXPORT_ORDER',
        'LOGISTIC_REPORT_EXPORT_WITHOUT_TRACK_CODE',
        'LOGISTIC_REPORT_EXPORT_1025_1',
        'LOGISTIC_REPORT_EXPORT_1025_2',
        'LOGISTIC_REPORT_EXPORT_1025_3',
        'LOGISTIC_REPORT_EXPORT_1025_3_2',
        'LOGISTIC_REPORT_EXPORT_1025_4',
        'LOGISTIC_REPORT_EXPORT_1025_4_2',
        'LOGISTIC_REPORT_EXPORT_DELIVERY_INTERVAL',
        'LOGISTIC_REPORT_EXPORT_DELIVERY_POINT_CHANGE',
        'LOGISTIC_REPORT_CSV_ORDER',
        'LOGISTIC_REPORT_PRODUCT_LIST',
      ],
    },
    component: () => import('@/pages/settings/DashboardReports'),
  },
]
