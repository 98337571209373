var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',_vm._g(_vm._b({staticClass:"ui-btn-width",class:{
    'filter': _vm.filterBtn,
    'width-120': _vm.w120,
    'width-120-min': _vm.w120min,
    'width-50': _vm.w50,
    'width-120-sm-100': _vm.w120sm100,
    'width-sm-100': _vm.wsm100,
  }},'v-btn',_vm.attributes,false),_vm.$listeners),[(_vm.$scopedSlots.iconLeft)?_c('span',{class:{'mr-2': !_vm.onlyLeftIcon && !_vm.isMobileIcon}},[_vm._t("iconLeft")],2):_vm._e(),_c('span',{class:_vm.textClasses},[_vm._t("default")],2),(_vm.$scopedSlots.iconRight)?_c('span',{staticClass:"ml-2"},[_vm._t("iconRight")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }