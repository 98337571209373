import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ApiService from '@/services/api.service'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/vue-i18n'
import VueApexCharts from 'vue-apexcharts'
import '@/helpers/vue-filters'
import '@/plugins/directives'
import '@/plugins/globalComponents'
import '@/plugins/vue-multiselect'
import '@/plugins/v-tootip'
import '@/plugins/moment'
import '@/plugins/autocomplete'
import '@/plugins/notification.socket'
import VueTheMask from 'vue-the-mask'
import packageJson from '../package.json'
import ModalConfirmReload from "@/components/ui/modals/ModalConfirmReload.vue";
Vue.use(VueTheMask)

Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.component('apex-chart', VueApexCharts)

const currentVersion = packageJson.version;
const versionKey = 'appVersion';
const savedVersion = localStorage.getItem(versionKey);

if (savedVersion !== currentVersion) {
  const authTokenKey = 'id_token';
  const languageKey = 'language';
  const authToken = localStorage.getItem(authTokenKey);
  const language = localStorage.getItem(languageKey);
  localStorage.clear();
  if (authToken) localStorage.setItem(authTokenKey, authToken);
  if (language) localStorage.setItem(languageKey, language);
  localStorage.setItem(versionKey, currentVersion);
  if(!localStorage.getItem('reload_modal_shown')){
    setTimeout(() => store.dispatch('ModalModule/open', {
      extends: ModalConfirmReload,
    }), 5000)
  }
}

Vue.http = Vue.prototype.$http = ApiService

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
