export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    createdAt: {
      value: '',
      operator: 'bwn',
    },
    country: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    orderSubstatus: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    region: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    prepayment: {
      value: null,
      operator: 'in',
    },
    city: {
      id: {
        value: '',
        operator: 'in',
      },
      district: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    offer: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    deliveryService: {
      id: {
        value: '',
        operator: 'in',
      },
      type: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    products: {
      product: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    warehouse: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    id: {
      value: '',
      operator: 'in',
    },
  },
  custom_filter: {
    hours_from_approve: '',
    attribute: [],
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
    dateType: 'createdAt',
    selectedColumns: [],
  },
}
