<template>
  <v-btn
    v-on="$listeners"
    v-bind="attributes"
    class="ui-btn-width"
    :class="{
      'filter': filterBtn,
      'width-120': w120,
      'width-120-min': w120min,
      'width-50': w50,
      'width-120-sm-100': w120sm100,
      'width-sm-100': wsm100,
    }">
    <span v-if="$scopedSlots.iconLeft" :class="{'mr-2': !onlyLeftIcon && !isMobileIcon}">
      <slot name="iconLeft" />
    </span>

    <span :class="textClasses">
      <slot />
    </span>

    <span v-if="$scopedSlots.iconRight" class="ml-2">
      <slot name="iconRight" />
    </span>
  </v-btn>
</template>
<script>
export default {
  name: 'UiButton',
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    filterBtn: {
      type: Boolean,
      default: false
    },
    mobileIcon: {
      type: Boolean,
      default: false
    },
    w50: {
      type: Boolean,
      default: false
    },
    w120: {
      type: Boolean,
      default: false
    },
    w120min: {
      type: Boolean,
      default: false
    },
    w120sm100: {
      type: Boolean,
      default: false
    },
    wsm100: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'default',
      validator(type) {
        return ['default', 'outline', 'flat'].includes(type)
      },
    },
    variant: {
      type: String,
      default: 'primary',
      validator(type) {
        return ['primary', 'error', 'warning', 'success', 'white'].includes(type)
      },
    },
    size: {
      type: String,
      default: 'default',
      validator(type) {
        return ['default', 'x-small', 'small', 'large', 'x-large'].includes(type)
      },
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    iconBorderColor: {
      type: String,
      default: 'black lighten-3',
    },
    round: {
      type: Boolean,
      default: false,
    },
    onlyLeftIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    attributes() {
      return {
        ...this.themeProperties,
        ...this.iconProperties,
        [this.size]: true,
        block: this.block,
        rounded: this.round,
        disabled: this.disabled,
      }
    },
    iconBtnSlot(){
      return this.$vuetify.breakpoint.mdAndUp ? 'iconLeft' : 'default'
    },
    themeProperties() {
      switch (this.theme) {
        case 'default':
          return {
            elevation: 0,
            color: this.variant,
          }
        case 'outline':
          return {
            color: !this.disabled ? 'black lighten-6' : '',
            elevation: 0,
            outlined: true,
          }
        case 'flat':
          return {
            elevation: 0,
            text: true,
          }
        default:
          return {}
      }
    },
    isMobileIcon(){
      return this.mobileIcon && this.$vuetify.breakpoint.smAndDown
    },
    iconProperties() {
      if (!(this.isIcon || this.isMobileIcon)) {
        return {}
      }
      return {
        outlined: this.theme !== 'flat',
        color: this.active ? 'primary' : this.iconBorderColor,
        icon: true,
        tile: !this.round,
        fab: false,
      }
    },
    textClasses() {
      switch (this.theme) {
        case 'default':
          return {}
        case 'outline':
          return !this.disabled ? 'black--text text--darken-3' : ''
        case 'flat':
          return 'black--text text--darken-3 font-weight-regular'
        default:
          return {}
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-btn-width.filter {
  width: 120px !important;
  background-color: #EFF3FE !important;
  color: #5757E2 !important;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 48% !important;
  }
}

.width-120 {
  width: 120px !important;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 47% !important;
  }
}
.width-120-min {
  min-width: 120px !important;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 47% !important;
  }
}

.width-50 {
  width: 49% !important;
}

.width-120-sm-100 {
  width: 120px !important;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 100% !important;
  }
}
.width-sm-100 {
  width: fit-content !important;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 100% !important;
  }
}
</style>