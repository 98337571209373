export default [
    {
        path: '/settings/invoice-translations',
        name: 'pageInvoiceTranslationsList',
        meta: { title: 'invoiceTranslations.listTitle' },
        component: () => import('@/pages/settings/invoice-translations'),
    },
    {
        path: '/settings/invoice-translations/create',
        name: 'pageInvoiceTranslationsCreate',
        meta: { title: 'invoiceTranslations.createFormTitle' },
        component: () => import('@/pages/settings/invoice-translations/create'),
    },
    {
        path: '/settings/invoice-translations/:id/edit',
        name: 'pageInvoiceTranslationsEdit',
        meta: { title: 'invoiceTranslations.editFormTitle' },
        component: () => import('@/pages/settings/invoice-translations/id/edit'),
    },
]
