import { DATE_FORM_FORMAT } from '@/constans/date'
import moment from 'moment'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    dateFrom: moment().startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment().endOf('month').format(DATE_FORM_FORMAT),
    firstGroup: 'month',
    secondGroup: 'offer_group',
    currency: 6,
    offers: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    offerGroups: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    tags: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    niches: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    salesModels: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    deliveryServices: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    deliveryType: '',
    countries: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    regions: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    cities: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    userGroups: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    webs: [],
    questionnaires: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    filterDateBy: 'delivered',
  },
  sortBy: ['day'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
